.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* /CUSTOMIZE SCROLLBAR LOOK/ */
::-webkit-scrollbar {
  width: 5px !important;
  scroll-margin-left: 20px !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb {
  background: #131417 !important;
  /* border-radius: 2px; */
}

::-webkit-scrollbar-thumb:hover {
  background: #aeaeae !important;
}

.hvEJdW {
  border-width: 1 !important;
  border-color: #fff !important;
  width: 100% !important;
}

.customtabbutton {
  /* min-width: 16.66% !important; */
  color: #000 !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
}

.tabpanelzeropadding {
  padding: 0px !important;
}

.dropZoneCustom {
    min-height: 130px !important;
}

.dropZoneCustomText{
  font-size: small !important;
  margin-bottom: 0px !important;
}